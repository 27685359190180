class App {
    constructor() {
        this.datas = [];
    }

    init() {
        this.scroll_fixed();
        this.sp_nav();
        this.alert();
    }

    alert() {
        $('.alert').util('alertMes');
    }
    sp_nav() {
        $('.gsnav').on('click', function() {
            $('.menu-trigger', this).toggleClass('active');
            $('header .header-nav nav').slideToggle();
            return false;
        });
    }

    scroll_fixed() {
        const $navs = $('.home .visual .header-nav');
        const $header_navs = $('.home header .header-nav');
        let $win = $(window);
        let navHeight = $navs.height();
        let fixedClass = 'is-fixed';

        $win.on('load scroll', function() {
            let value = $win.scrollTop();
            if (value > navHeight) {
                $header_navs.addClass(fixedClass);
            } else {
                $header_navs.removeClass(fixedClass);
            }
        });
    }
}
var app = new App();
$(function() {
    app.init();
});

$(window).on('load', function() {
    // app.footer_event();
});
